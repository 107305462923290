import { defineStore } from "pinia";
import { type EventDate } from "~/assets/types/eventData";

export const useEventStore = defineStore("useEventStore", () => {
  const eventId = ref<string>();
  const lockedDate = ref<EventDate>();
  const signedInUserParticipantId = ref<string>();
  const signedInUserId = ref<string>();

  function $reset() {
    eventId.value = undefined;
    lockedDate.value = undefined;
    signedInUserParticipantId.value = undefined;
    signedInUserId.value = undefined;
  }

  return {
    eventId,
    lockedDate,
    signedInUserParticipantId,
    signedInUserId,
    $reset,
  };
});
